.cv_page {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  
    [data-header-desktop] & {
      padding-top: var(--header-height);
    }
  
    [data-header-desktop=normal] & {
      padding-top: 0;
    }
    [theme=dark] & { 
        color:#ddd ;
        a {
            color:#ddd;
        }
        a:hover {
            color: #58abcb;
        }
    }
    
}

#maina {
    font-family: calibri;
    margin-top: 5em;

    p {
        margin:8px 0px;
    }
    h2, h3 {
        margin:3px 0px;
    }
}
#titre {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    align-items: center;
    text-align: center;
}

#c1 { 
    grid-area: 1 / 1 / 2 / 2;
    font-size: 31px;
    font-weight: bold;
    
}
#c2 { 
    grid-area: 2 / 1 / 3 / 2;
    font-size: 31px;
    align-self: start; 
}
#pic { 
    grid-area: 1 / 2 / 3 / 3;
    border-radius: 9px; 
}

#infos {
    margin-top: 10px;
    margin-bottom: 16px;
    display: grid;
    grid-template-areas: "adresse ref info";
    justify-items: center;
    align-items: center;
    grid-row-gap: 10px;
    text-align: center;

    h2 {
        font-size:17px;
    }

    div {
        background-color: #f8f8f8;
        border-radius: 10px;
        padding: 3px 6%;
        [theme=dark] & { 
            background-color: #4e4e4e;
        }
    }
}

#c3 {
    grid-area: adresse;
}
#c4 {
    grid-area: ref;
    a {
        font-size: 30px;
    }
}
#c5 { grid-area: info; }

#c6 ul {
    display: inline-block;
}

#comp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 16px;
    grid-column-gap: 20px;
    text-align: center;
    grid-row-gap: 2em;
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }
}

#c7 ul li {
    display: inline-block;
    
}

#cert {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    grid-row-gap: 20px;
    text-align: center;
    margin: 20px 0px;
    img {
        width: 60px;
        display: block;
        margin: 0px auto;
    }
    .cert_apr {
        font-size: 13px;
        background-color: #087e00;
        color: white;
        padding: 0px 3px;
        border-radius: 4px;
    }
    
    .cert_pen {
        font-size: 13px;
        background-color: #c96b00;
        color: white;
        padding: 0px 3px;
        border-radius: 4px;
    }

    .more_info:hover {
        opacity: 0.5;
    }

    #back_ajust {
        [theme=dark] & { 
            background-color: #e7e7e7;
            border-radius: 13px;
            padding: 0px 4px;
        }
    }
}

#c6, #c7, #c8, #c9 {
    margin-top: 15px;
    padding: 5px 10px;
    border-radius: 5px;
}

.exp, .forma {
    padding: 0px 10px;
    margin-top: 15px;
}
.temps {
    float: right;
    margin-left: 10px;
    text-align: right;
}
.intitule b {
    font-size: 18px;
    background-color: #e7e7e7;
    padding: 2px 6px;
    border-radius: 4px;
    [theme=dark] & { 
        background-color: #4e4e4e;
    }
}



@media only screen and (max-width: 1440px) {
    .cv_page {
        width: 72%;
    }
}
@media only screen and (max-width: 900px) {
    .cv_page {
        width: 80%;
    }
    #cert, #comp {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 650px) {
    .cv_page {
        width: 100%;
    }
    #c1, #c2 {
        margin: 15px 0px;
    }
    #pic { 
        display: none; 
    }
    #infos {
        grid-template-areas: "adresse" "ref" "info";
    }
    #cert, #comp {
        grid-template-columns: repeat(2, 1fr);
    }
}

.archive .archive-item-date {
    width: 6em;
}

.pic-art {
    margin: 0px 50%;
}